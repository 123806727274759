import React from "react"

import { Layout } from "../components"
import { ContactForm } from "@ultralight-io/components"

const NotFoundPage = () => (
  <Layout>
    <div className="min-h-3/4 flex items-center justify-center bg-white dark:bg-gray-800 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 dark:text-white">The page you are trying reach doesn't exist</h2>
          <p className="mt-6 text-center text-xl text-gray-900 dark:text-white">Still can't find what you are looking for? Reach out using the contact form and hopefully we can shine a light on it for you.</p>
        </div>
        <a
          href="/"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light"
        >
          Return to the Home page
        </a>
      </div>
    </div>
    <ContactForm action="/api/contact" />
  </Layout>
)

export default NotFoundPage
